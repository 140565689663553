import React, { useState, useEffect, createRef } from 'react';
import ReactQuill from 'react-quill';
import {
  Input, Upload, Spin, Icon,
} from 'antd';
import Prism from 'prismjs';

import iconPlaceholder from '../../img/screenshot-placeholder.png';

const { TextArea } = Input;

const handleChange = ({ file }, setImage) => {
  if (!['done', 'error'].includes(file.status)) {
    setImage({ uploading: true });
  }
  if (file.status === 'done') {
    setImage({
      uid: file.uid,
      src: file.response.data.link,
      name: file.response.data.name,
      thumbUrl: file.thumbUrl,
      uploading: false,
    });
  }
};
export default (props) => {
  const textArea = createRef();
  const [code, setCode] = useState(`
  Click here to edit code snippet
`);
  const [isEditable, setIsEditable] = useState(false);
  const [image, setImage] = useState({ uploading: false });
  useEffect(() => {
    if (!isEditable) {
      const block = document.getElementById(props.id);
      Prism.highlightElement(block);
    }
    if (isEditable) {
      textArea.current.focus();
    }
  }, [isEditable, props.id, textArea]);
  return (
    <div className='code-example-wrap'>
      <div className='row'>
        <div className='col-9 d-flex' style={{ padding: 0 }}>
          <div className='code'>
            <ReactQuill
              className='image-name'
              id={`usage${props.id}_title`}
              theme='bubble'
              defaultValue={`Feature ${props.index + 1}`}
              placeholder={`Feature ${props.index + 1}`}
              modules={{ toolbar: [] }}
              style={{ width: '50%' }}
              />
            {
            isEditable
              ? <TextArea
                autosize={{ maxRows: 15 }}
                id={`usage${props.id}`}
                onChange={e => setCode(e.target.value)}
                onBlur={e => setIsEditable(false)}
                ref={textArea}
                value={code} />
              : <pre onClick={e => setIsEditable(true)} className='container'>
                <code
                  id={props.id}
                  className='language-javascript line-numbers white-space'
                  >
                  {code || 'click here to add code sample'}
                </code>
              </pre>
          }
          </div>
        </div>
        <div className='col-3'>
          <div
            className='code-output-img d-flex justify-content-end align-items-center'>
            <Upload
              action='https://api.imgur.com/3/image'
              listType='picture-card'
              headers={{
                'X-Requested-With': null,
                Authorization: 'Bearer 88b2b0e352b52c1fd0237af74c62d64436c7fd96',
              }}
              name='image'
              onRemove={() => setImage({ uploading: false })}
              onChange={info => handleChange(info, setImage)}
            >
              <Spin spinning={image.uploading} >
                {
                  image.src
                    ? <img
                      style={{ width: 140, objectFit: 'contain' }}
                      id={`usageImage${props.id}`}
                      src={image.src}
                      className={image.src && 'uploaded'}
                      alt='Code output Status'
                    />
                    : <img
                      style={{ width: 140, objectFit: 'contain' }}
                      src={iconPlaceholder}
                      className={image.src && 'uploaded'}
                      alt='Code output Status'
                    />
                }

                {
                image.src && <span><Icon
                  type='delete'
                  className='remove-icon'
                  theme='filled'
                  onClick={e => e.stopPropagation() || setImage({ uploading: false })} />
                </span>
              }
              </Spin>
            </Upload>
          </div>
        </div>
      </div>
    </div>
  );
};
