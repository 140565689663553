export default ({
  repoName,
  authorName,
  goal,
  screenshots,
  roadmap,
  installations,
  examples,
  contact,
}) => `
# ${repoName}

[![CI Status](http://img.shields.io/travis/${authorName}}/${repoName}.svg?style=flat)](https://travis-ci.org/${authorName}/${repoName})
[![Version](https://img.shields.io/cocoapods/v/${repoName}.svg?style=flat)](http://cocoapods.org/pods/${repoName})
[![License](https://img.shields.io/cocoapods/l/${repoName}.svg?style=flat)](http://cocoapods.org/pods/${repoName})
[![Platform](https://img.shields.io/cocoapods/p/${repoName}.svg?style=flat)](http://cocoapods.org/pods/${repoName})

# Goal

${goal}

# Screenshots


| ${screenshots.map(screenshot => screenshot.imageUrl && `<img src="${screenshot.imageUrl}" alt="${screenshot.title}" width="250">`).join(' | ')} |
| ${screenshots.map(screenshot => screenshot.imageUrl && `--`).join(' | ')} |
| ${screenshots.map(screenshot => screenshot.title && `${screenshot.title}`).join(' | ')} |



# Roadmap

${roadmap}

# Installation

${installations.map(installation => `
### ${installation.title}

${installation.description}

`).join('')}

# Usage

<table>
 <thead>
<tr>
<th>Example code</th>
<th>Screenshot</th>
</tr>
</thead>
<tr>
<td>
 ${examples[0].title}
  <pre lang="swift" style='width: 600px'>
  ${examples[0].source_code}
  </pre>
</td>
<td>
  ${examples[0].imageUrl ? `<img src="${examples[0].imageUrl}" alt="${examples[0].title}" width="250">` : ''}
</td>
</tr>
<tr>
<td>
 ${examples[1].title}
  <pre lang="swift" style='width: 600px'>
  ${examples[1].source_code}
  </pre>
</td>
<td>
  ${examples[1].imageUrl ? `<img src="${examples[1].imageUrl}" alt="${examples[1].title}" width="250">` : ''}
</td>
</tr>
<tr>
<td>
 ${examples[2].title}
  <pre lang="swift" style='width: 600px'>
  ${examples[2].source_code}
  </pre>
</td>
<td>
  ${examples[2].imageUrl ? `<img src="${examples[2].imageUrl}" alt="${examples[2].title}" width="250">` : ''}
</td>
</tr>
</table>

# Contacts

${contact}`;
