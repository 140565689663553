import React from 'react';
import moment from 'moment';

import InfoIcon from '../../img/info icon.png';

const GithubInfo = props => (
  <div className='github-data'>
    <div className='row no-gutters'>
      <div className='col-3'>
        <div className='data-content data-last-update'>
          <h4 className='data-type'>
            Stars
          </h4>
          <div
            className='data-value d-flex justify-content-start align-items-center'>
            <i className='fa fa-star mr-2' />
            <p className='mb-0 text-bold-secondary'>
              {props.starCount && props.starCount.toLocaleString()}
            </p>
          </div>
        </div>
      </div>
      <div className='col-3'>
        <div className='data-content data-issue'>
          <h4 className='data-type'>
            Last Update
          </h4>
          <div className='data-value'>
            <p className='mb-0 text-bold-primary'>
              {props.lastUpdate && moment(props.lastUpdate).fromNow()}
            </p>
          </div>
        </div>
      </div>
      <div className='col-3'>
        <div className='data-content data-stars'>
          <h4 className='data-type'>
            Issues (last 2 months)
          </h4>
          <div className='data-value'>
            <p className='text-bold-secondary'>
              <a
                href={`${props.repoUrl}/issues?q=is%3Aopen+is%3Aissue`}
                target='_blank'
                rel='noopener noreferrer'
              >
                Opened {props.openIssues}
              </a>
              &nbsp; / &nbsp;
              <a
                href={`${props.repoUrl}/issues?q=is%3Aissue+is%3Aclosed`}
                target='_blank'
                rel='noopener noreferrer'
              >
                Closed {props.closedIssues}
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className='col-3'>
        <div className='data-content data-license'>
          <h4 className='data-type'>
            License
          </h4>
          <div
            className='data-value d-flex justify-content-start align-items-center'>
            <p className='text-bold-primary'>
              {props.license}
            </p>
          </div>
          <img
            className='info-icon'
            src={InfoIcon}
            alt=''
          />
        </div>
      </div>
    </div>
  </div>
);

export default GithubInfo;
