import React from 'react';

const Installation = ({ installations }) => (installations && installations.length ? (
  <section className='sec-sep installation mt-5'>
    <h2 className='sec-title'>
      Installation
    </h2>
    <ul className='nav nav-pills'>
      <li>
        <a
          className='active'
          data-toggle='tab'
          href='#home'
        >
          {installations[0].title}
        </a>
      </li>
      <li>
        <a
          data-toggle='tab'
          href='#menu1'
        >
          {installations[1].title}
        </a>
      </li>
      <li>
        <a
          data-toggle='tab'
          href='#menu2'
        >
          {installations[2].title}
        </a>
      </li>
    </ul>
    <div className='tab-content'>
      <div
        id='home'
        className='tab-pane fade show active'
      >
        <p>
          {installations[0].description}
        </p>
      </div>
      <div
        id='menu1'
        className='tab-pane fade'
      >
        <p>
          {installations[1].description}
        </p>
      </div>
      <div
        id='menu2'
        className='tab-pane fade'
      >
        <p>
          {installations[2].description}
        </p>
      </div>
    </div>
  </section>
) : null);

export default Installation;
