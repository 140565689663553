import React, { PureComponent } from 'react';
import axios from 'axios';
import downloadFile from 'js-file-download';
import {
  message, Spin,
} from 'antd';
import { API_ROOT } from '../../env-vars';
import RepoHeader from './RepoHeader';
import OwnerInfo from './OwnerInfo';
import GithubInfo from './GithubInfo';
import OverviewHeader from './OverviewHeader';
import Overview from './Overview';
import ScreenShots from './ScreenShots';
import Installation from './Installation';
import Usage from './Usage';
import Contacts from './Contacts';
import Roadmap from './Roadmap';
import template from '../../template';

class ProjectEdit extends PureComponent {
  state = {
    isLoading: false,
  };

  storagePrefix = `${this.props.match.params.user}_${this.props.match.params.repo}`

  repoUrl = `https://github.com/${this.props.match.params.user}/${this.props.match.params.repo}`;

  async componentDidMount() {
    if (!this.props.context.info.name) {
      try {
        this.setState({ isLoading: true });
        const { data: postData } = await axios.post(`${API_ROOT}/repo/data`, {
          repoUrl: this.repoUrl,
        });
        const [data] = postData;
        this.props.context.setInfo({
          ...this.formatData(data),
        });
      } catch (e) {
        message.error('something went wrong');
      } finally {
        this.setState({ isLoading: false });
      }
    }
  }

  formatData = data => ({
    id: data.id,
    commits: data.commits && data.commits.last2Month,
    totalCommits: data.commits && data.commits.total,
    name: data.title,
    desc: data.subtitle,
    owner: data.owner || {},
    starCount: data.star,
    lastUpdate: data.commits && data.commits.lastDate,
    openIssues: data.issues && data.issues.opened,
    closedIssues: data.issues && data.issues.closed,
    license: data.license,
    homePage: data.html_url,
    language: data.language,
    info: data.readme && data.readme.content,
    repoUrl: '',
    issues: '',
  })

  render() {
    const {
      name, desc,
      owner, homePage,
      starCount, openIssues, lastUpdate, license, closedIssues,
      language, commits, totalCommits,
    } = this.props.context.info;
    return (
      <Spin spinning={this.state.isLoading}>
        <section className='main'>
          <div className='container'>
            <RepoHeader
              name={name}
              desc={desc}
              storagePrefix={this.storagePrefix}
              info={this.props.context.info}
              setInfo={this.props.context.setInfo}
              publish={this.publish}
              download={this.download}
          />
            <OwnerInfo
              avatar={owner.avatar_url}
              ownerName={owner.name}
              ownerUrl={owner.html_url}
              homePage={homePage}
              storagePrefix={this.storagePrefix}
          />
            <GithubInfo
              openIssues={openIssues}
              closedIssues={closedIssues}
              starCount={starCount}
              lastUpdate={lastUpdate}
              license={license.name}
              repoUrl={this.repoUrl}
              storagePrefix={this.storagePrefix}
          />
            <section className='main-content'>
              <OverviewHeader
                language={language}
                commits={commits}
                totalCommits={totalCommits}
                storagePrefix={this.storagePrefix}
              />
              <div className='overview-main-content'>
                {
                  <Overview title='Goals' />
              }
                <ScreenShots />
                <Roadmap title='Roadmap' />
                <Installation />
                <Usage />
                <Contacts />
              </div>
            </section>
          </div>
        </section>
      </Spin>
    );
  }

  publish = async () => {
    const { info } = this.props.context;
    const screenshots = [1, 2, 3, 4]
      .map(item => document.getElementById(`screenshot_${item}`) && ({
        title: document.querySelector(`#screenshotName_${item} > div > div > p`).innerText,
        imageUrl: document.getElementById(`screenshot_${item}`).src,
      }))
      .filter(val => val);
    const examples = ['sample1', 'sample2', 'sample3'].map(item => ({
      title: document.querySelector(`#usage${item}_title > div > div > p`).innerText,
      source_code: document.getElementById(item).innerText,
      imageUrl: document.getElementById(`usageImage${item}`) && document.getElementById(`usageImage${item}`).src,
    }));
    const requestData = {
      id: info.id,
      author_name: this.props.match.params.user,
      repo_name: this.props.match.params.repo,
      title: document.querySelector('#title > div > div > p').innerText,
      description: document.querySelector('#description > div > div > p').innerText,
      goal: document.querySelector('#goal > div > div > p').innerText,
      roadmap: document.querySelector('#roadmap > div > div > p').innerText,
      contact: document.querySelector('#contact > div > div > p').innerText,
      screenshots,
      installations: [
        {
          title: 'CocoaPods',
          description: document.querySelector('#cocoapods > div > div > p').innerText,
        },
        {
          title: 'Carthage',
          description: document.querySelector('#carthage > div > div > p').innerText,
        },
        {
          title: 'Manual',
          description: document.querySelector('#manual > div > div > p').innerText,
        },
      ],
      examples,
    };
    try {
      const { data } = await axios.post(`${API_ROOT}/publish/product`, requestData);
      if (data.status === 201) {
        window.open(`/published-repo/${data.data.id}/${data.data.author_name}/${data.data.repo_name}`, '_blank');
      }
      message.success('Your website has been published!');
    // eslint-disable-next-line no-empty
    } catch (e) {
      message.error('Oops. Something went wrong. Please try again');
    }
  }

  download = async () => {
    const { info } = this.props.context;
    const screenshots = [1, 2, 3, 4]
      .map(item => document.getElementById(`screenshot_${item}`) && ({
        title: document.querySelector(`#screenshotName_${item} > div > div > p`).innerText,
        imageUrl: document.getElementById(`screenshot_${item}`).src,
      }))
      .filter(val => val);
    const examples = ['sample1', 'sample2', 'sample3'].map(item => ({
      title: document.querySelector(`#usage${item}_title > div > div > p`).innerText,
      source_code: document.getElementById(item).innerText,
      imageUrl: document.getElementById(`usageImage${item}`) && document.getElementById(`usageImage${item}`).src,
    }));
    const requestData = {
      id: info.id,
      authorName: this.props.match.params.user,
      repoName: this.props.match.params.repo,
      title: document.querySelector('#title > div > div > p').innerText,
      description: document.querySelector('#description > div > div > p').innerText,
      goal: document.querySelector('#goal > div > div > p').innerText,
      roadmap: document.querySelector('#roadmap > div > div > p').innerText,
      contact: document.querySelector('#contact > div > div > p').innerText,
      screenshots,
      installations: [
        {
          title: 'CocoaPods',
          description: document.querySelector('#cocoapods > div > div > p').innerText,
        },
        {
          title: 'Carthage',
          description: document.querySelector('#carthage > div > div > p').innerText,
        },
        {
          title: 'Manual',
          description: document.querySelector('#manual > div > div > p').innerText,
        },
      ],
      examples,
    };
    try {
      // const { data } = await axios.post(`${API_ROOT}/download/product`, requestData);
      // console.log(data);
      // if (data.status === 201) {
      //   const url = window.URL.createObjectURL(new Blob([data.data]));
      //   const link = document.createElement('a');
      //   link.href = url;
      //   link.setAttribute('download', 'README.md');
      //   document.body.appendChild(link);
      //   link.click();
      //   link.remove();
      // }
      console.log(requestData);
      downloadFile(template(requestData), 'README.md');
      message.success('README.md generated!');
    // eslint-disable-next-line no-empty
    } catch (e) {
      // console.log(e);
      message.error('Oops. Something went wrong. Please try again');
    }
  }
}

export default ProjectEdit;
