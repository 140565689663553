import React from 'react';
import ReactQuill from 'react-quill';

const Contacts = () => (
  <section className='sec-sep contacts'>
    <h2 className='sec-title'>
      Contacts
    </h2>
    <ReactQuill
      theme='bubble'
      id='contact'
      defaultValue='Click to edit your Contact info'
      placeholder='Click to edit your Contact info'
      modules={{ toolbar: [] }}
    />
  </section>
);

export default Contacts;
