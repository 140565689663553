import React, { useState } from 'react';
import {
  BrowserRouter as Router, Route, Switch,
} from 'react-router-dom';

import { RepoContext } from '../contexts';

import RepoInput from './RepoInput';
import RepoEdit from './editPage';
import PublishedRepo from './PublishedRepo';


export default () => {
  const [info, setInfo] = useState({
    id: '',
    commits: '',
    totalCommits: '',
    name: '',
    desc: '',
    owner: {},
    starCount: '',
    lastUpdate: '',
    openIssues: '',
    closedIssues: '',
    license: '',
    homePage: '',
    language: '',
    info: '',
    repoUrl: '',
    issues: '',
  });
  return (
    <Router>
      <Switch>
        <RepoContext.Provider value={{ info, setInfo }} >
          <RepoContext.Consumer>
            {
              context => <Route
                exact
                path='/edit/:user/:repo'
                render={props => <RepoEdit {...props} context={context} />}
              />
            }
          </RepoContext.Consumer>
          <Route
            exact
            path='/published/:id/:user/:repo'
            component={PublishedRepo}
            />
          <Route
            exact
            path='/'
            component={RepoInput}
            />
        </RepoContext.Provider>
      </Switch>
    </Router>
  );
};
