import React from 'react';

import Swift from '../../img/swift.png';
import AppleLogo from '../../img/apple-logo.png';

const OverviewHeader = props => (
  <header className='overview-header'>
    <div className='row no-gutters'>
      <div className='col-3'>
        <div className='data-content data-commit'>
          <h4 className='data-type'>
            Commits (last 2 months)
          </h4>
          <div
            className='data-value d-flex justify-content-start align-items-center'>
            <p className='mb-0 text-bold-primary'>
              {props.commits}
            </p>
          </div>
        </div>
      </div>
      <div className='col-3'>
        <div className='data-content data-requirement'>
          <h4 className='data-type'>
            Total Commits
          </h4>
          <div className='data-value'>
            <p className='mb-0 text-bold-primary'>
              {props.totalCommits}
            </p>
          </div>
        </div>
      </div>
      <div className='col-3'>
        <div className='data-content data-language'>
          <h4 className='data-type'>
            Language
          </h4>
          <div
            className='data-value d-flex justify-content-start align-items-center'>
            <img
              className='swift-icon mr-2'
              src={Swift}
              alt='Swift Icon'
            />
            <p className='mb-0 text-bold-primary'>
              {props.language}
            </p>
          </div>
        </div>
      </div>
      <div className='col-3'>
        <div className='data-content data-platform'>
          <div
            className='data-value h-100 d-flex justify-content-end align-items-center'>
            <img
              className='apple-logo-sm'
              src={AppleLogo}
              alt='Apple Logo'
            />
          </div>
        </div>
      </div>
    </div>
  </header>
);

export default OverviewHeader;
