import React, { useEffect } from 'react';

import Prism from 'prismjs';


export default (props) => {
  useEffect(() => {
    const block = document.getElementById(props.id);
    Prism.highlightElement(block);
  }, [props.id]);
  return (
    <div className='code-example-wrap'>
      <div className='row'>
        <div className='col-9 d-flex' style={{ padding: 0 }}>
          <div className='code'>
            <p>{props.title}</p>
            <pre className='container'>
              <code
                id={props.id}
                className='language-javascript line-numbers white-space'
              >
                {props.source_code || 'click here to add code sample'}
              </code>
            </pre>
          </div>
        </div>
        <div className='col-3'>
          <div
            className='code-output-img d-flex justify-content-end align-items-center'>
            <img
              style={{ width: 140, objectFit: 'contain' }}
              id={`usageImage${props.id}`}
              src={props.imageUrl}
              className='uploaded'
              alt='Code output Status' />
          </div>
        </div>
      </div>
    </div>
  );
};
