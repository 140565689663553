import React from 'react';
import ReactQuill from 'react-quill';

const Overview = props => (
  <section className='sec-sep overview'>
    <div className='row'>
      <div className='col-2'>
        <h2 className='sec-title'>
          {props.title}
        </h2>
      </div>
      {
          // todo uncomment and fix with data from back-end once finalized.
          // <div className='col-10'>
          //   <ul className='d-flex justify-content-end list-unstyled product-hashtag-list'>
          //     <li>
          //       #swift4
          //     </li>
          //     <li>
          //       #swift
          //     </li>
          //     <li>
          //       #ios1
          //     </li>
          //     <li>
          //       #ui-components
          //     </li>
          //     <li>
          //       #alerts
          //     </li>
          //   </ul>
          // </div>
          }
    </div>
    <div className='row'>
      <div className='col'>
        <ReactQuill
          theme='bubble'
          id='goal'
          defaultValue='Click here to edit your Goals'
          placeholder='Click here to edit your Goals'
        />
      </div>
    </div>
  </section>
);

export default Overview;
