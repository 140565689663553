import React from 'react';

const Contacts = ({ contacts }) => (
  <section className='sec-sep contacts'>
    <h2 className='sec-title'>
      Contacts
    </h2>
    <p>
      {contacts}
    </p>
  </section>
);

export default Contacts;
