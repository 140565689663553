import React from 'react';
import CodeSample from './CodeSample';

export default ({ samples }) => (samples ? (
  <section className='sec-sep usage'>
    <h2 className='sec-title'>
        Usage
    </h2>
    <p className='sec-desc'>
        
    </p>
    {samples.map((sample, index) => <CodeSample key={index} {...sample} id={index} />)}
  </section>
) : null);
