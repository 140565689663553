import React from 'react';
import CodeSample from './CodeSample';

const samples = [
  { id: 'sample1' },
  { id: 'sample2' },
  { id: 'sample3' },
];

export default () => (
  <section className='sec-sep usage'>
    <h2 className='sec-title'>
        Usage
    </h2>
    <p className='sec-desc'>
    </p>
    {samples.map((sample, index) => <CodeSample key={sample.id} index={index} {...sample} />)}
  </section>
);
