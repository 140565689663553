import React, { Fragment, useState } from 'react';
import ReactQuill from 'react-quill';
import { Button, message } from 'antd';

import { RepoContext } from '../../contexts';

message.config({
  duration: 3,
  maxCount: 1,
});
// const getValue = (key, propsValue, defaultValue) => {
//   const storageValue = localStorage.getItem(key);
//   if (storageValue) {
//     return storageValue;
//   }
//   if (propsValue) {
//     return propsValue;
//   }
//   return defaultValue;
// };

// const setValue = (key, value, propsValue, defaultValue = 'sample text') => {
//   if (value && value.trim().length) {
//     localStorage.setItem(key, value);
//     return value;
//   }
//   if (propsValue && propsValue.trim().length) {
//     localStorage.setItem(key, propsValue);
//     return propsValue;
//   }
//   localStorage.setItem(key, defaultValue);
//   return defaultValue;
// };


const RepoHeader = ({ publish, download }) => {
  const [loading, setLoading] = useState(false);

  const handlePublish = async () => {
    setLoading(true);
    await publish();
    setLoading(false);
  };
  const handleDownload = async () => {
    setLoading(true);
    await download();
    setLoading(false);
  };
  return (
    <Fragment>
      {
        // TODO uncomment or remove once design is finalized
        // <div className='page-status'>
        //   <i className='fa fa-check' />
        //   <span className='status-text'>
        //     Save in Draft
        //   </span>
        // </div>
      }
      <header className='page-header d-flex justify-content-between align-items-center'>
        <div className='header-title'>
          <RepoContext.Consumer>
            {
            ({ info, setInfo }) => <ReactQuill
              id='title'
              theme='bubble'
              className='title'
              value={info.name}
              placeholder='Enter your title'
              // onChange={(a, b, c, editor) => setInfo({ ...info, name: editor.getContents() })}
            />
          }
          </RepoContext.Consumer>
          <RepoContext.Consumer>
            {
            ({ info, setInfo }) => <ReactQuill
              id='description'
              theme='bubble'
              value={info.desc}
              placeholder='Enter your description'
              // onChange={(a, b, c, editor) => setInfo({ ...info, desc: editor.getContents() })}
              />
          }
          </RepoContext.Consumer>
        </div>
        <div className='header-action'>
          <Button className='header-action-btn publish-button' onClick={handlePublish} loading={loading}>
            Publish
          </Button>
          <button className='header-action-btn'  onClick={handleDownload} loading={loading}>
            Download
          </button>
        </div>
      </header>
    </Fragment>
  );
};
export default RepoHeader;
