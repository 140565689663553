import React from 'react';
import ReactQuill from 'react-quill';

const Installation = props => (
  <section className='sec-sep installation mt-5'>
    <h2 className='sec-title'>
      Installation
    </h2>
    <ul className='nav nav-pills'>
      <li>
        <a
          className='active'
          data-toggle='tab'
          href='#home'
        >
          CocoaPods
        </a>
      </li>
      <li>
        <a
          data-toggle='tab'
          href='#menu1'
        >
          Carthage
        </a>
      </li>
      <li>
        <a
          data-toggle='tab'
          href='#menu2'
        >
          Manual
        </a>
      </li>
    </ul>
    <div className='tab-content'>
      <div
        id='home'
        className='tab-pane fade show active'
      >
        <ReactQuill
          id='cocoapods'
          theme='bubble'
          defaultValue='enter installation manual'
          placeholder='enter installation manual'
        />
      </div>
      <div
        id='menu1'
        className='tab-pane fade'
      >
        <ReactQuill
          id='carthage'
          theme='bubble'
          defaultValue='enter installation manual'
          placeholder='enter installation manual'
        />
      </div>
      <div
        id='menu2'
        className='tab-pane fade'
      >
        <ReactQuill
          id='manual'
          theme='bubble'
          defaultValue='enter installation manual'
          placeholder='enter installation manual'
        />
      </div>
    </div>
  </section>
);

export default Installation;
