import React, { Fragment } from 'react';


const RepoHeader = ({ title, description }) => (
  <Fragment>
    <header className='page-header d-flex justify-content-between align-items-center'>
      <div className='header-title'>
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
    </header>
  </Fragment>
);
export default RepoHeader;
