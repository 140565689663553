import React, { Component, Fragment } from 'react';
import {
  Modal, Upload, Icon, Col, Spin,
} from 'antd';
import ReactQuill from 'react-quill';
import iconPlaceholder from '../../img/screenshot-placeholder.png';

class ScreenShots extends Component {
  state = {
    previewVisible: false,
    previewImage: '',
    fileList: [
      {
        id: 1,
        name: 'Feature 1',
      },
      {
        id: 2,
        name: 'Feature 2',
      },
      {
        id: 3,
        name: 'Feature 3',
      },
      {
        id: 4,
        name: 'Feature 4',
      },
    ],
    base64Array: [],
  };

  closeModal = () => this.setState({ previewVisible: false })

  handlePreview = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  }

  handleChange = ({ file }, stateItem) => {
    if (!['done', 'error'].includes(file.status)) {
      this.setState(state => ({
        fileList: state.fileList.map((item) => {
          if (item.id !== stateItem.id) {
            return item;
          }
          return { ...stateItem, uploading: true };
        }),
      }));
    }
    if (file.status === 'done') {
      this.setState(state => ({
        fileList: state.fileList.map((item) => {
          if (item.id !== stateItem.id) {
            return item;
          }
          return {
            ...stateItem,
            uid: file.uid,
            src: file.response.data.link,
            name: file.response.data.name,
            thumbUrl: file.thumbUrl,
            uploading: false,
          };
        }),
      }));
    }
  }

  handleCancel = (file) => {
    this.setState(state => ({
      fileList: state.fileList.map(item => (item.uid === file.uid
        ? { id: item.id, name: item.name }
        : item)),
    }));
  }

  handleRemove = (e, file) => {
    e.stopPropagation();
    this.setState(state => ({
      fileList: state.fileList.filter(item => item.id !== file.id),
    }));
  }

  render() {
    const {
      previewVisible, previewImage, fileList,
    } = this.state;
    return (
      <section className='sec-sep screenshots'>
        <h2 className='sec-title'>
          Screenshots
        </h2>
        <div className='div'>
          <div className='screenshot-wrap'>
            {
                fileList.map(file => (<Col span={6} key={file.id}>
                  {
                    file.src
                      ? <div className='image-container'>
                        <div className='ant-upload-list ant-upload-list-picture-card' />
                        <div className='ant-upload-list-item ant-upload-list-item-done'>
                          <div className='ant-upload-list-item-info'>
                            <img
                              id={`screenshot_${file.id}`}
                              className='screenshot-fig'
                              src={file.src}
                              alt={file.name}
                            />
                          </div>
                          {
                              // <span onClick={() => this.handlePreview(file)} className='upload-icon'>
                              //   <Icon type='camera' />
                              // </span>
                          }
                          <span onClick={() => this.handleCancel(file)}>
                            <Icon
                              type='delete'
                              className='remove-icon'
                              theme='filled'
                            />
                          </span>
                        </div>
                      </div>
                      : <Upload
                        action='https://api.imgur.com/3/image'
                        listType='picture-card'
                        onPreview={this.handlePreview}
                        className='upload-button'
                        multiple
                        headers={{
                          'X-Requested-With': null,
                          Authorization: 'Bearer 88b2b0e352b52c1fd0237af74c62d64436c7fd96',
                        }}
                        name='image'
                        onRemove={() => this.handleCancel(file)}
                        onChange={info => this.handleChange(info, file)}
                        >
                        <Spin spinning={file.uploading || false}>
                          <img
                            className='screenshot-fig'
                            src={iconPlaceholder}
                            alt='img1'
                            onClick={e => e.stopPropagation()}
                          />
                          <Icon type='picture' className='upload-icon' theme='filled' />
                        </Spin>
                      </Upload>
                  }
                  {
                    <ReactQuill
                      className='image-name'
                      theme='bubble'
                      id={`screenshotName_${file.id}`}
                      defaultValue={file.name || `Feature ${file.id}`}
                      placeholder='Enter Feature name'
                      modules={{ toolbar: [] }}
                    />
                  }
                </Col>))
              }
            <Fragment >
              <Modal visible={previewVisible} footer={null} onCancel={this.closeModal}>
                <img alt='example' style={{ width: '100%' }} src={previewImage} />
              </Modal>
            </Fragment>
          </div>
        </div>
      </section>
    );
  }
}

export default ScreenShots;
