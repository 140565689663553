import React from 'react';

const Overview = props => (
  <section className='sec-sep overview'>
    <div className='row'>
      <div className='col-2'>
        <h2 className='sec-title'>
          {props.title}
        </h2>
      </div>
    </div>
    <div className='row'>
      <div className='col'>
        <p>{props.text}</p>
      </div>
    </div>
  </section>
);

export default Overview;
