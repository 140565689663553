import React from 'react';
import {
  Col,
} from 'antd';

const ScreenShots = (props) => {
  const {
    fileList,
  } = props;
  return (
    <section className='sec-sep screenshots'>
      <h2 className='sec-title'>
        Screenshots
      </h2>
      <div className='div'>
        <div className='screenshot-wrap'>
          {
            fileList && fileList.map((file, index) => (
              <Col span={6} key={index}>
                <div className='image-container'>
                  <div className='ant-upload-list ant-upload-list-picture-card' />
                  <div className='ant-upload-list-item ant-upload-list-item-done'>
                    <div className='ant-upload-list-item-info'>
                      <img
                        className='screenshot-fig'
                        src={file.imageUrl}
                        alt={file.title}
                      />
                    </div>
                  </div>
                </div>
                <p>
                  {file.title}
                </p>
              </Col>
            ))
          }
        </div>
      </div>
    </section>
  );
};

export default ScreenShots;
