import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Spin,
} from 'antd';
import { API_ROOT } from '../../env-vars';

import RepoHeader from './RepoHeader';
import OwnerInfo from './OwnerInfo';
import GithubInfo from './GithubInfo';
import OverviewHeader from './OverviewHeader';
import Overview from './Overview';
import ScreenShots from './ScreenShots';
import Installation from './Installation';
import Usage from './Usage';
import Contacts from './Contacts';
import Roadmap from './Roadmap';

const PublishedRepo = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  useEffect(() => {
    async function getRepoData(params) {
      try {
        setLoading(true);
        const { data: postData } = await axios.get(`${API_ROOT}/publish/product/${props.match.params.id}`);
        setData(postData);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    }
    getRepoData();
  }, [props.match.params.id]);
  return (
    <Spin spinning={loading}>
      <section className='main'>
        <div className='container'>
          <RepoHeader
            title={data.title}
            description={data.description}
          />
          <OwnerInfo
            avatar={data.github && data.github.owner_avatar_url}
            ownerName={props.match.params.user}
            ownerUrl={`https://github.com/${props.match.params.user}`}
            homePage={`https://github.com/${props.match.params.user}/${props.match.params.repo}`}
          />
          <GithubInfo
            openIssues={data.github && data.github.open_issue_count}
            closedIssues={data.github && data.github.closed_issues_count}
            starCount={data.github && data.github.stars_count}
            lastUpdate={data.github && data.github.last_commit_date}
            license={data.github && data.github.license && data.github.license.name}
            repoUrl={data.github && data.github.url}
          />
          <section className='main-content'>
            <OverviewHeader
              language={data.github && data.github.main_language}
              commits={data.github && data.github.commits_count}
              totalCommits={data.github && data.github.all_commit_count}
              />
            <div className='overview-main-content'>
              {
                <Overview title='Goals' text={data.goal} />
              }
              <ScreenShots fileList={data.screenshots} />
              <Roadmap title='Roadmap' text={data.roadmap} />
              <Installation installations={data.installations} />
              <Usage samples={data.examples} />
              <Contacts contacts={data.contact} />
            </div>
          </section>
        </div>
      </section>
    </Spin>
  );
};

export default PublishedRepo;
