import React from 'react';
import ReactQuill from 'react-quill';

const Overview = props => (
  <section className='sec-sep overview'>
    <div className='row'>
      <div className='col-2'>
        <h2 className='sec-title'>
          {props.title}
        </h2>
      </div>
    </div>
    <div className='row'>
      <div className='col'>
        <ReactQuill
          theme='bubble'
          id='roadmap'
          defaultValue='Click here to edit your Roadmap'
          placeholder='Click here to edit your Roadmap'
          />
      </div>
    </div>
  </section>
);

export default Overview;
