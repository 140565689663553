import React from 'react';

const OwnerInfo = props => (
  <div className='owner-info d-flex justify-content-between align-items-center'>
    <div className='avatar-name'>
      <p>
        Owner
      </p>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='rounded-circle mr-2 avatar-wrap'>
          <img
            src={props.avatar}
            alt='Avatar'
          />
        </div>
        <div className='owner-name text-bold-secondary'>
          <a
            href={props.ownerUrl}
            target='_blank'
            rel='noopener noreferrer'
            >
            {props.ownerName}
          </a>
        </div>
      </div>
    </div>
    <div className='github-link'>
      <a
        href={props.homePage}
        target='_blank'
        rel='noopener noreferrer'
        >
        {props.homePage}
      </a>
    </div>
  </div>
);

export default OwnerInfo;
