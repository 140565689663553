import React, { PureComponent } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
  Form, Button, Input, message, Steps,
} from 'antd';
import Axios from 'axios';
import logo from '../img/mogoni-logo.png';
import { RepoContext } from '../contexts';
import { API_ROOT } from '../env-vars';

const { Step } = Steps;

const FormItem = Form.Item;

class RepoInput extends PureComponent {
  state = {
  }

  componentDidMount() {
    this.setState({ rendering: false });
  }

  handleSubmit = async (e, context) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ isLoading: true });
        try {
          const { data: postData } = await Axios.post(`${API_ROOT}/repo/data`, {
            repoUrl: values.githubUrl,
          });
          this.setState({ isLoading: false });
          const [data] = postData;
          context.setInfo({
            id: data.id,
            commits: data.commits.last2Month,
            totalCommits: data.commits.total,
            name: data.title,
            desc: data.subtitle,
            owner: data.owner || {},
            starCount: data.star,
            lastUpdate: data.commits.lastDate,
            openIssues: data.issues.opened,
            closedIssues: data.issues.closed,
            license: data.license,
            homePage: data.html_url,
            language: data.language,
            info: data.readme.content,
          });
          const repoUrl = values.githubUrl.split('github.com/')[1];
          this.props.history.push(`edit/${repoUrl}`);
        } catch ({ response }) {
          this.setState({ isLoading: false });
          message.error(response && response.data && response.data.message, 5);
        }
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <section className='repo-input'>
        <div className='container container-styles d-flex flex-column align-items-center h-100'>
          <div className='header'>
            <div style={{ float: 'left' }}>
              <img alt='logo' src={logo} style={{ height: 70, width: 70 }} />
            </div>
            <h2 className='header-title'>
              Start your GitHub project
            </h2>
          </div>
          <div className='content'>
            <Steps current={1} progressDot={<span />} style={{ width: '50%', margin: '0 auto' }}>
              <Step />
              <Step />
              <Step />
              <Step />
            </Steps>
            <h5 className='text-center content-title'>
                To get started enter your GitHub project full URL
            </h5>
            <div className='mt-5'>
              <RepoContext.Consumer>
                {
                  context => (
                    <Form
                      onSubmit={e => this.handleSubmit(e, context)}
                    >
                      <FormItem>
                        <p style={{ width: '50%', margin: '0 auto', color: 'white' }}>
                          PROJECT URL
                        </p>
                        {
                          getFieldDecorator('githubUrl', {
                            rules: [
                              {
                                required: true, message: 'please input project url',
                              },
                            ],
                          })(
                            <Input
                              className='form-control form-control__input'
                              placeholder='e.g. https://github.com/Opinionz/OpinionzAlertView'
                              type='text' />,
                          )
                        }
                      </FormItem>
                      <FormItem className='text-center'>
                        <Button
                          htmlType='submit'
                          className='form-control__button'
                          loading={this.state.isLoading}
                        >
                          NEXT
                        </Button>
                      </FormItem>
                    </Form>
                  )
                }
              </RepoContext.Consumer>
            </div>
          </div>
          <div className='footer'>
            <Link to='#'>
                Contact Us
            </Link>
            <Link to='#'>
                Privacy
            </Link>
            <Link to='#'>
                Disclaimer
            </Link>
            <Link to='#'>
                Terms of service
            </Link>
          </div>
        </div>
      </section>
    );
  }
}
const InputForm = Form.create()(RepoInput);

export default withRouter(InputForm);
